<script lang="ts">
export default {};
</script>

<template>
	<svg
		viewBox="0 0 24 24"
		width="24"
		height="24"
		fill="none"
		fill-rule="evenodd"
		clip-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.17 8l-2-2H4v12h16V8h-8.83zM4 4h6l2 2h8a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2l.01-12A2 2 0 014 4zm10.44 5l4 4-4 4v-3H11v-2h3.44V9z"
		/>
	</svg>
</template>
