<script lang="ts">
export default {};
</script>

<template>
	<svg
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill-rule="evenodd"
		clip-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
	>
		<path
			d="M1 1h6v6H1V1zm0 8h6v6H1V9zm6 8H1v6h6v-6zM9 1h6v6H9V1zm6 8H9v6h6V9zm-6 8h6v6H9v-6zM23 1h-6v6h6V1zm-6 8h6v6h-6V9zm6 8h-6v6h6v-6z"
		/>
	</svg>
</template>
