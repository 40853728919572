<script lang="ts">
export default {}
</script>

<template>
  <svg fill="none" viewBox="0 0 48 48">
    <rect
      width="46"
      height="46"
      x="1"
      y="1"
      stroke="#fff"
      stroke-width="2"
      rx="23"
    />
    <g clip-path="url(#a)">
      <path
        fill="#fff"
        d="m24 22.232 6.187-6.187 1.768 1.767L25.767 24l6.188 6.187-1.768 1.768L24 25.767l-6.188 6.188-1.767-1.768L22.232 24l-6.187-6.188 1.767-1.767L24 22.232Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M9 9h30v30H9z" />
      </clipPath>
    </defs>
  </svg>
</template>
