<script lang="ts">
export default {};
</script>

<template>
	<svg
		viewBox="0 0 24 24"
		width="24"
		height="24"
		fill="none"
		fill-rule="evenodd"
		clip-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.38 3.6c-.38-.4-.83-.6-1.36-.6H6.98c-.53 0-1 .2-1.4.6-.38.42-.56.88-.56 1.42V21L12 18l6.98 3V5.02c0-.54-.2-1-.6-1.41zm-8.05 11.5l6.7-6.7-1.4-1.4-5.3 5.3-1.92-1.93L7 11.79l3.33 3.33z"
		/>
	</svg>
</template>
