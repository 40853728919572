<script setup lang="ts">
import { type NuxtLinkProps } from 'nuxt/app'

const props = withDefaults(
  defineProps<
    NuxtLinkProps & {
      title?: string
      variant?: 'default' | 'ghost'
      color?: 'dark' | 'light'
    }
  >(),
  { variant: 'default', color: 'dark' }
)

const NuxtLink = resolveComponent('NuxtLinkLocale')

// Extract NuxtLinkProps, which we're using to define all link-related props (to, href, target, ...)
// They're then manually spread onto the root element.
const { variant, color, to, href, target, rel } = toRefs(props)
const linkProps = computed(() => ({
  to: to.value,
  href: href.value,
  target: target.value,
  rel: rel.value,
}))
const isLink = computed(() => !!props.to)
const isAnchor = computed(() => !props.to && !!props.href)
const componentType = computed(() =>
  isLink.value ? NuxtLink : isAnchor.value ? 'a' : 'button'
)
</script>

<template>
  <div>
    <component
      :is="componentType"
      v-bind="linkProps"
      :class="[
        'inline-block text-16 lg:text-18 leading-none font-semibold',
        'rounded-full border-2',
        'px-5 lg:px-6 py-3',
        'focus:outline-none focus:ring',
        'transition-colors duration-150',
        // Hover layer
        'relative hover:before:opacity-100 before:opacity-0 before:transition-opacity before:duration-150 before:absolute before:-inset-0.5 before:rounded-full',
        {
          // Default & dark
          'bg-black border-black text-white before:bg-ci-gray-700':
            variant === 'default' && color === 'dark',
          // Default & light
          'bg-white border-white text-black before:bg-ci-gray-100':
            variant === 'default' && color === 'light',
          // Ghost & dark
          'bg-transparent border-black text-black hover:text-white before:bg-black':
            variant === 'ghost' && color === 'dark',
          // Ghost & light
          'bg-transparent border-white text-white hover:text-black before:bg-white':
            variant === 'ghost' && color === 'light',
        },
      ]"
      @click="$emit('click')"
    >
      <span class="relative z-10">
        <slot :data="{ title, to }"></slot>
      </span>
    </component>
  </div>
</template>
