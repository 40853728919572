import CustomIconDirectus from './custom-icons/directus.vue'
import CustomIconBookmarkSave from './custom-icons/bookmark_save.vue'
import CustomIconBox from './custom-icons/box.vue'
import CustomIconCommitNode from './custom-icons/commit_node.vue'
import CustomIconGrid1 from './custom-icons/grid_1.vue'
import CustomIconGrid2 from './custom-icons/grid_2.vue'
import CustomIconGrid3 from './custom-icons/grid_3.vue'
import CustomIconGrid4 from './custom-icons/grid_4.vue'
import CustomIconGrid5 from './custom-icons/grid_5.vue'
import CustomIconGrid6 from './custom-icons/grid_6.vue'
import CustomIconSignalWifi1Bar from './custom-icons/signal_wifi_1_bar.vue'
import CustomIconSignalWifi2Bar from './custom-icons/signal_wifi_2_bar.vue'
import CustomIconSignalWifi3Bar from './custom-icons/signal_wifi_3_bar.vue'
import CustomIconFlipHorizontal from './custom-icons/flip_horizontal.vue'
import CustomIconFlipVertical from './custom-icons/flip_vertical.vue'
import CustomIconFolderMove from './custom-icons/folder_move.vue'
import CustomIconFolderLock from './custom-icons/folder_lock.vue'
import CustomIconLogout from './custom-icons/logout.vue'
import CustomIconCloseContact from './custom-icons/close_contact.vue'

export const components: Record<string, any> = {
  CustomIconDirectus,
  CustomIconBookmarkSave,
  CustomIconBox,
  CustomIconCommitNode,
  CustomIconGrid1,
  CustomIconGrid2,
  CustomIconGrid3,
  CustomIconGrid4,
  CustomIconGrid5,
  CustomIconGrid6,
  CustomIconSignalWifi1Bar,
  CustomIconSignalWifi2Bar,
  CustomIconSignalWifi3Bar,
  CustomIconFlipHorizontal,
  CustomIconFlipVertical,
  CustomIconFolderMove,
  CustomIconFolderLock,
  CustomIconLogout,
  CustomIconCloseContact,
}
